// FrameToolbar.js
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useColorMode } from '../../../contexts/ColorMode.js';
import CommonButton from '../../common/CommonButton.tsx';
import './toolbar.css';
import ReactSlider from 'react-slider';
import {
  FaChevronRight,
  FaTimes,
  FaChevronUp,
  FaChevronDown,
  FaDownload,
} from 'react-icons/fa';
import AudioOptionsDialog from './audio/AudioOptionsDialog.js';

import SecondaryButton from '../../common/SecondaryButton.tsx';
import VerticalWaveform from '../util/VerticalWaveform.js';
import DualThumbSlider from '../util/DualThumbSlider.js';
import TimeRuler from '../util/TimeRuler.js';
import RangeOverlaySlider from '../util/RangeOverlaySlider.js';
import { FRAME_TOOLBAR_VIEW } from '../../../constants/Types.ts';
import AudioTrackSlider from '../util/AudioTrackSlider.js';
import DropdownButton from '../util/DropdownButton.js';
import { useAlertDialog } from '../../../contexts/AlertDialogContext.js';
import BatchPrompt from '../util/BatchPrompt.js';

import AudioEffectsToolbar from './audio/AudioEffectsToolbar.js';
import { createPortal } from 'react-dom';
import { FaChevronLeft, FaEye } from 'react-icons/fa6';
import { FaRedo } from 'react-icons/fa';
const MAX_VISIBLE_LAYERS = 10;
const MIN_LAYER_HEIGHT = 20; // in pixels

export default function FrameToolbar(props) {
  const {
    layers,
    setSelectedLayer,
    submitRenderVideo,
    setLayerDuration,
    currentLayerSeek,
    setCurrentLayerSeek,
    totalDuration,
    showAddAudioToProjectDialog,
    audioFileTrack,

    startPlayFrames,
    downloadVideoDisplay,
    renderedVideoPath,
    sessionId,
    updateSessionLayer,
    setIsLayerSeeking,
    isVideoGenerating,
    showAudioTrackView,
    frameToolbarView,
    audioLayers,
    updateAudioLayer,
    isAudioLayerDirty,
    removeAudioLayer,
    handleVolumeChange,
    updateChangesToActiveLayers,
    addLayerToComposition,
    copyCurrentLayerBelow,
    removeSessionLayer,
    addLayersViaPromptList,
    defaultSceneDuration,
    isCanvasDirty,
    downloadLink,
    submitRegenerateFrames,
    applySynchronizeAnimationsToBeats,
    applySynchronizeLayersToBeats,
    applySynchronizeLayersAndAnimationsToBeats,
  } = props;

  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'light' ? 'bg-cyber-white' : 'bg-gray-800';
  const bg2Color = colorMode === 'light' ? 'bg-stone-200' : 'bg-gray-700';
  let bg3Color = colorMode === 'light' ? 'bg-neutral-100' : 'bg-neutral-800';
  const bgSelectedColor =
    colorMode === 'light' ? 'bg-stone-200 shadow-lg' : 'bg-stone-950 shadow-lg';
  const textColor = colorMode === 'light' ? 'text-cyber-black' : 'text-neutral-100';
  const borderColor = colorMode === 'light' ? 'border-gray-300' : 'border-gray-600';

  const [highlightBoundaries, setHighlightBoundaries] = useState({ start: 0, height: 0 });
  const totalDurationInFrames = Math.floor(totalDuration * 30); // Convert total duration to frames (30 fps)
  const [startSelectDurationInFrames, setStartSelectDurationInFrames] = useState(0);
  const [endSelectDurationInFrames, setEndSelectDurationInFrames] = useState(0);

  const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);
  const [openPopupLayerIndex, setOpenPopupLayerIndex] = useState(null);

  const [visibleStartTime, setVisibleStartTime] = useState(0);
  const [visibleEndTime, setVisibleEndTime] = useState(totalDuration);

  const [isAudioDuckingEnabled, setIsAudioDuckingEnabled] = useState(false);

  const [effectiveVisibleDisplaySliderRange, setEffectiveVisibleDisplaySliderRange] = useState([
    0,
    totalDurationInFrames,
  ]);

  const parentRef = useRef(null);

  // State to manage visible layers
  const [visibleLayersStartIndex, setVisibleLayersStartIndex] = useState(0);

  const { openAlertDialog, closeAlertDialog } = useAlertDialog();

  const [effectiveSliderRange, setEffectiveSliderRange] = useState([0, totalDurationInFrames]);

  const [selectedFrameRange, setSelectedFrameRange] = useState([0, totalDurationInFrames]);


  // Memoize visibleLayers to prevent unnecessary re-renders
  const visibleLayers = useMemo(() => {
    // Compute cumulative start frames
    const cumulativeStartFrames = [];
    let totalFrames = 0;
    layers.forEach((layer) => {
      cumulativeStartFrames.push(totalFrames);
      totalFrames += layer.duration * 30; // Convert to frames
    });
  
    // Find layers that overlap with selectedFrameRange
    const [startFrame, endFrame] = selectedFrameRange;
  
    const newVisibleLayers = [];
    for (let i = 0; i < layers.length; i++) {
      const layerStartFrame = cumulativeStartFrames[i];
      const layerEndFrame = layerStartFrame + layers[i].duration * 30;
      if (layerEndFrame > startFrame && layerStartFrame < endFrame) {
        newVisibleLayers.push(layers[i]);
      }
    }
  
    return newVisibleLayers;
  }, [layers, selectedFrameRange]);


  // Animation States
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState(null); // 'prev' or 'next'
  const [incomingVisibleLayers, setIncomingVisibleLayers] = useState([]);

  const layerRefs = useRef({}); // Add this line to store refs to layer items
  const [popupPosition, setPopupPosition] = useState({ top: '50%', transform: 'translateY(-50%)' });

  // New state variables for duration change
  const [pendingDuration, setPendingDuration] = useState(null);
  const [durationChanged, setDurationChanged] = useState(false);

  // Refs for layers
  const currentLayersRef = useRef(null);
  const incomingLayersRef = useRef(null);

  // Popup ref
  const popupRef = useRef(null);

  // Compute whether we can navigate further
  const canGoPrev = visibleLayersStartIndex > 0;
  const canGoNext = visibleLayersStartIndex + MAX_VISIBLE_LAYERS < layers.length;

  // Handle Previous Click
  const handlePrevClick = () => {
    if (!canGoPrev || isAnimating) return;

    const numLayersToMove = Math.min(3, visibleLayersStartIndex);
    const newStartIndex = visibleLayersStartIndex - numLayersToMove;
    const newVisibleLayers = layers.slice(newStartIndex, newStartIndex + MAX_VISIBLE_LAYERS);

    setIncomingVisibleLayers(newVisibleLayers);
    setAnimationDirection('prev');
    setIsAnimating(true);
  };

  // Handle Next Click
  const handleNextClick = () => {
    if (!canGoNext || isAnimating) return;

    const numLayersToMove = Math.min(
      3,
      layers.length - (visibleLayersStartIndex + MAX_VISIBLE_LAYERS)
    );
    const newStartIndex = visibleLayersStartIndex + numLayersToMove;
    const newVisibleLayers = layers.slice(newStartIndex, newStartIndex + MAX_VISIBLE_LAYERS);

    setIncomingVisibleLayers(newVisibleLayers);
    setAnimationDirection('next');
    setIsAnimating(true);
  };

  // Effect to handle the animation transition
  useEffect(() => {
    if (isAnimating && incomingVisibleLayers.length > 0) {
      const height = parentRef.current.clientHeight;

      // Start positions
      const currentStartY = 0;
      const incomingStartY = animationDirection === 'next' ? height : -height;

      // End positions
      const currentEndY = animationDirection === 'next' ? -height : height;
      const incomingEndY = 0;

      // Apply initial positions
      currentLayersRef.current.style.transform = `translateY(${currentStartY}px)`;
      incomingLayersRef.current.style.transform = `translateY(${incomingStartY}px)`;

      // Trigger reflow to ensure the browser picks up the starting positions
      void currentLayersRef.current.offsetWidth;

      // Apply transition
      currentLayersRef.current.style.transition = 'transform 0.5s ease-in-out';
      incomingLayersRef.current.style.transition = 'transform 0.5s ease-in-out';

      // Apply end positions
      currentLayersRef.current.style.transform = `translateY(${currentEndY}px)`;
      incomingLayersRef.current.style.transform = `translateY(${incomingEndY}px)`;

      const timer = setTimeout(() => {
        // After animation duration, update the visible layers
        setVisibleLayersStartIndex((prevIndex) =>
          animationDirection === 'next' ? prevIndex + 3 : prevIndex - 3
        );
        setIsAnimating(false);
        setAnimationDirection(null);
        setIncomingVisibleLayers([]);

        // Reset styles
        currentLayersRef.current.style.transform = '';
        currentLayersRef.current.style.transition = '';
        incomingLayersRef.current.style.transform = '';
        incomingLayersRef.current.style.transition = '';

        // Reset currentLayerSeek and selectedLayerIndex if out of range
        const newSelectedIndex =
          animationDirection === 'next'
            ? visibleLayersStartIndex + 3
            : visibleLayersStartIndex - 3;

        if (
          selectedLayerIndex < newSelectedIndex ||
          selectedLayerIndex >= newSelectedIndex + MAX_VISIBLE_LAYERS
        ) {
          setSelectedLayerIndex(newSelectedIndex);
          setSelectedLayer(layers[newSelectedIndex]);
        }

        // Adjust currentLayerSeek to the start of the new visible range
        const visibleStartTime = layers
          .slice(0, newSelectedIndex)
          .reduce((acc, layer) => acc + layer.duration, 0);
        setCurrentLayerSeek(Math.floor(visibleStartTime * 30));
      }, 500); // Duration should match CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [
    isAnimating,
    incomingVisibleLayers,
    animationDirection,
    visibleLayersStartIndex,
    layers,
    selectedLayerIndex,
    setSelectedLayerIndex,
    setSelectedLayer,
    setCurrentLayerSeek,
  ]);

  useEffect(() => {
    if (openPopupLayerIndex !== null) {
      const layerElement = layerRefs.current[openPopupLayerIndex];
      if (layerElement) {
        const layerRect = layerElement.getBoundingClientRect();
        const layerTop = layerRect.top + window.scrollY; // position relative to document
        const layerHeight = layerRect.height;
        const popupHeight = 150; // height of the popup
        const viewportHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        let top = layerTop;

        // Check if the popup would overflow beyond the viewport
        if (layerTop + popupHeight > window.scrollY + viewportHeight) {
          // Adjust the top so that the bottom aligns with the bottom of the layer
          top = layerTop + layerHeight - popupHeight;
        }

        // Also check if the popup would go beyond the document
        if (top + popupHeight > documentHeight) {
          top = documentHeight - popupHeight;
        }

        setPopupPosition({ top: top + 'px', transform: 'translateY(0)' });
      }
    }
  }, [openPopupLayerIndex]);

  // Update effectiveVisibleDisplaySliderRange when visibleLayersStartIndex changes


  useEffect(() => {
    if (layers.length > 0) {
      const [startFrame, endFrame] = selectedFrameRange;
      const visibleStartTime = startFrame / 30;
      const visibleEndTime = endFrame / 30;
  
      setEffectiveVisibleDisplaySliderRange([startFrame, endFrame]);
  
      // Set visible start and end times in seconds
      setVisibleStartTime(visibleStartTime);
      setVisibleEndTime(visibleEndTime);
  
      // Adjust currentLayerSeek if it moves out of the new visible range
      if (currentLayerSeek < startFrame || currentLayerSeek > endFrame) {
        setCurrentLayerSeek(startFrame);
      }
    }
  }, [layers, selectedFrameRange, currentLayerSeek, setCurrentLayerSeek]);
  
  // Update highlightBoundaries based on selectedLayerIndex
  useEffect(() => {
    if (selectedLayerIndex >= 0 && parentRef.current && visibleLayers.length > 0) {
      const selectedItemIndex = visibleLayers.findIndex(
        (layer) => layer === layers[selectedLayerIndex]
      );
      if (selectedItemIndex === -1) {
        // Selected layer is not in visibleLayers
        setHighlightBoundaries({ start: 0, height: 0 });
        return;
      }

      const startDuration = visibleLayers
        .slice(0, selectedItemIndex)
        .reduce((acc, layer) => acc + layer.duration, 0);

      const currentLayerDuration =
        pendingDuration != null
          ? pendingDuration
          : visibleLayers[selectedItemIndex].duration;

      const totalVisibleDuration = visibleLayers.reduce(
        (acc, layer) => acc + layer.duration,
        0
      );

      const parentHeight = parentRef.current.clientHeight;

      const startPixels = (startDuration / totalVisibleDuration) * parentHeight;
      const heightPixels = (currentLayerDuration / totalVisibleDuration) * parentHeight;

      setHighlightBoundaries({ start: startPixels, height: heightPixels });

      updateLayerDurations();
    }
  }, [selectedLayerIndex, layers, visibleLayersStartIndex, visibleLayers, pendingDuration]);

  const updateLayerDurations = () => {
    if (selectedLayerIndex >= 0 && layers && layers.length > 0) {
      const startDuration = layers
        .slice(0, selectedLayerIndex)
        .reduce((acc, layer) => acc + layer.duration, 0);

      const currentLayerDuration =
        pendingDuration != null ? pendingDuration : layers[selectedLayerIndex].duration;

      setStartSelectDurationInFrames(startDuration * 30);
      setEndSelectDurationInFrames((startDuration + currentLayerDuration) * 30);
    }
  };


  const handleViewRangeSliderChange = (val) => {
  const [startFrame, endFrame] = val;
  
  // Update effectiveSliderRange
  setEffectiveSliderRange([startFrame, endFrame]);
  
  // Update selectedFrameRange
  setSelectedFrameRange([startFrame, endFrame]);

  }

  const layerDurationUpdated = (val) => {
    const newDurationInFrames = val[1] - val[0];
    const newDuration = newDurationInFrames / 30;
    setPendingDuration(newDuration);
    setDurationChanged(true);
  };

  const layerDurationCellUpdated = (value, index) => {
    setPendingDuration(parseFloat(value));
    setDurationChanged(true);
  };

  const onUpdateDuration = () => {
    const newDuration = pendingDuration;
    setLayerDuration(newDuration, selectedLayerIndex);
    let layer = layers[selectedLayerIndex];
    layer.duration = newDuration;
    updateSessionLayer(layer);

    if (pendingDuration != null) {
      setPendingDuration(null);
      setDurationChanged(false);
      setOpenPopupLayerIndex(null);
    }
  };

  const onClosePopup = () => {
    setPendingDuration(null);
    setDurationChanged(false);
    setOpenPopupLayerIndex(null);
  };

  const removeLayer = (index) => {
    if (!layers || layers.length === 0) return;
    removeSessionLayer(index);
    setPendingDuration(null);
    setDurationChanged(false);
    setOpenPopupLayerIndex(null); // Close the popup when layer is removed
  };

  const setSelectedLayerDurationRange = (val) => {
    const newDurationInFrames = val[1] - val[0];
    const newDuration = newDurationInFrames / 30;
    setPendingDuration(newDuration);
    setDurationChanged(true);
  };

  const showSelectedAudioTrack = () => {
    const selectedAudioTrack = audioLayers.find((audioTrack) => audioTrack.isSelected);

    let selectedAudioTrackDisplay = <span />;
    if (selectedAudioTrack) {
      selectedAudioTrackDisplay = (
        <form onSubmit={updateChangesToActiveLayers}>
          <div className={`grid grid-cols-4 gap-1 `}>
            <div>
              <input
                type='text'
                value={selectedAudioTrack.startTime.toFixed(2)}
                className={`w-[50px] ${bgColor}`}
              />
              <div className='text-xs'>Start</div>
            </div>
            <div>
              <input
                type='text'
                defaultValue={selectedAudioTrack.volume.toFixed(2)}
                className={`w-[50px] ${bgColor}`}
                onChange={handleVolumeChange}
              />
              <div className='text-xs'>Volume</div>
            </div>
            <div>
              <SecondaryButton type='submit'>Update</SecondaryButton>
            </div>
            <div>
              <button
                className='bg-neutral-800 rounded-sm text-white'
                onClick={() => removeAudioLayer(selectedAudioTrack)}
              >
                <FaTimes className='inline-flex' />
                <div className='text-xs pl-1 pr-1 pb-1'>Remove</div>
              </button>
            </div>
          </div>
        </form>
      );
    }

    const handleAudioOptionsSubmit = ({ isAudioDucking, syncAnimations, syncLayers }) => {
      setIsAudioDuckingEnabled(isAudioDucking);

      if (syncAnimations && syncLayers) {
        applySynchronizeLayersAndAnimationsToBeats();
      } else if (syncAnimations) {
        applySynchronizeAnimationsToBeats();
      } else if (syncLayers) {
        applySynchronizeLayersToBeats();
      }
      closeAlertDialog();
    };

    const showAdditionOptionsDialog = () => {
      openAlertDialog(
        <div>
          <div>
            <FaTimes
              className='absolute right-2 top-2 cursor-pointer'
              onClick={closeAlertDialog}
            />
          </div>
          <AudioOptionsDialog
            onSubmit={handleAudioOptionsSubmit}
            initialDucking={isAudioDuckingEnabled}
            closeDialog={closeAlertDialog}
          />
        </div>
      );
    };

    return (
      <div className='flex'>
        <div className='inline-flex ml-2 mr-2'>{selectedAudioTrackDisplay}</div>
        <div className='ml-2 mr-2'>
          <SecondaryButton onClick={showAdditionOptionsDialog}>
            Additional Options
          </SecondaryButton>
        </div>
      </div>
    );
  };

  let layerSelectOverlay = <span />;
  if (highlightBoundaries && highlightBoundaries.height > 0) {
    let sliderStartRange = startSelectDurationInFrames > 0 ? startSelectDurationInFrames : 0;
    let sliderEndRange = endSelectDurationInFrames + 1;

    layerSelectOverlay = (
      <div
        className='layer-select-overlay absolute w-full z-10 top-0 left-0'
        style={{
          pointerEvents: 'none',
        }}
        onClick={() => {
          setOpenPopupLayerIndex(selectedLayerIndex);
        }}
      >
        <div
          className='relative'
          style={{
            top: `${highlightBoundaries.start}px`,
            height: `${highlightBoundaries.height}px`,
            width: '100%',
            pointerEvents: 'auto',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenPopupLayerIndex(selectedLayerIndex);
          }}
        >
          <RangeOverlaySlider
            onChange={setSelectedLayerDurationRange}
            onAfterChange={() => {
              setOpenPopupLayerIndex(selectedLayerIndex);
            }}
            min={sliderStartRange}
            max={sliderEndRange}
            value={[startSelectDurationInFrames, endSelectDurationInFrames]}
            highlightBoundaries={highlightBoundaries}
            layerDurationUpdated={layerDurationUpdated}
          />
        </div>
      </div>
    );
  }

  // Prepare layersList with current and incoming layers
  let layersList = <span />;
  if (visibleLayers.length > 0) {
    const totalVisibleDuration = visibleLayers.reduce(
      (acc, layer) => acc + layer.duration,
      0
    );

    const renderLayers = (layersToRender, keyPrefix) =>
      layersToRender.map((layer) => {
        const originalIndex = layers.indexOf(layer);
        const layerDuration = layer.duration; // in seconds
        let layerHeightPercentage = 0;

        if (totalVisibleDuration > 0) {
          layerHeightPercentage = (layerDuration / totalVisibleDuration) * 100;
        }

        let bgSelected = selectedLayerIndex === originalIndex ? bgSelectedColor : '';

        return (
          <div
            ref={(el) => {
              layerRefs.current[originalIndex] = el;
            }} // Assign ref to each layer item
            className={`cursor-pointer ${bg3Color} ${bgSelected} ml-1 mr-1 relative border-t ${borderColor} border-b ${borderColor}`}
            style={{
              height: `${layerHeightPercentage}%`,
              minHeight: `${MIN_LAYER_HEIGHT}px`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedLayerIndex(originalIndex);
              setSelectedLayer(layer);
              setOpenPopupLayerIndex(originalIndex);
            }}
            key={`${keyPrefix}_layer_${originalIndex}`}
          >
            {/* Labels */}
            <div className='absolute top-1 left-1 text-xs'>
              <div className='text-xs font-bold mb-4'>{originalIndex}</div>
              <div>{layerDuration.toFixed(1)}s</div>
            </div>
          </div>
        );
      });

    layersList = (
      <div
        className='layers-container relative h-full w-full '
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      >
        {/* Current Layers */}
        <div
          className='current-layers absolute top-0 left-0 w-full h-full'
          ref={currentLayersRef}
        >
          {renderLayers(visibleLayers, 'current')}
        </div>

        {/* Incoming Layers */}
        {isAnimating && incomingVisibleLayers.length > 0 && (
          <div
            className='incoming-layers absolute top-0 left-0 w-full h-full'
            ref={incomingLayersRef}
          >
            {renderLayers(incomingVisibleLayers, 'incoming')}
          </div>
        )}
      </div>
    );
  }

  const showBatchLayerDialog = () => {
    openAlertDialog(
      <BatchPrompt
        submitPromptList={submitPromptList}
        defaultSceneDuration={defaultSceneDuration}
      />
    );
  };

  const showAddedAudioTracks = () => {
    return audioLayers.map((audioTrack) => (
      <AudioTrackSlider
        key={audioTrack.id}
        audioTrack={audioTrack}
        totalDuration={totalDuration}
        onUpdate={updateAudioLayer}
      />
    ));
  };

  const submitPromptList = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const promptList = formData.get('promptList');
    const promptListArray = promptList
      .split('\n')
      .filter((prompt) => prompt.trim() !== '');
    const duration = formData.get('duration');
    const payload = {
      promptList: promptListArray,
      duration: duration,
    };
    addLayersViaPromptList(payload);
    closeAlertDialog();
  };

  let containerWdidth = 'w-[10%] z-1 opacity-100';
  if (frameToolbarView === FRAME_TOOLBAR_VIEW.AUDIO) {
    containerWdidth = 'w-[50%] z-[102] opacity-90';
  }

  let audioTrackViewDisplay = <span />;
  let audioSelectedTrackViewDisplay = <span />;

  if (frameToolbarView === FRAME_TOOLBAR_VIEW.AUDIO) {
    audioTrackViewDisplay = showAddedAudioTracks();
    audioSelectedTrackViewDisplay = showSelectedAudioTrack();
  }

  let mtop = 'mt-[52px]';
  let expandButtonLabel = (
    <div className='relative w-full cursor-pointer pb-1 block  bg-neutral-900'>
      <div className='inline-block'>Expand</div>
      <FaChevronRight className='inline-block ml-1 mr-1 text-xs font-bold mt-[-2px] ' />
    </div>
  );

  if (frameToolbarView === FRAME_TOOLBAR_VIEW.AUDIO) {
    expandButtonLabel = (
      <div className='relative '>
        <div className='absolute right-0 top-0 w-32 cursor-pointer pb-1 block  bg-neutral-950 '>
          <FaChevronLeft className='inline-block ml-1 mr-1 text-xs font-bold mt-[-2px] ' />
          <div className='inline-block'>Collapse</div>
        </div>
      </div>
    );
  }

  let topSubToolbar = (
    <div className='flex flex-row w-full'>
      <div className='basis-3/4 font-bold ml-0 text-sm mt-1'>
        <div>Scenes</div>
        <div>
          <FaChevronUp
            className={`inline-flex ${canGoPrev ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={canGoPrev ? handlePrevClick : null}
          />
          <FaChevronDown
            className={`inline-flex ${canGoNext ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={canGoNext ? handleNextClick : null}
          />
        </div>
      </div>
      <div className='basis-1/4'>
        <DropdownButton
          addLayerToComposition={addLayerToComposition}
          copyCurrentLayerBelow={copyCurrentLayerBelow}
          showBatchLayerDialog={showBatchLayerDialog}
        />
      </div>
    </div>
  );

  let prevDownloadLink = <span />;

  if (downloadLink) {
    prevDownloadLink = (
      <SecondaryButton>
        <a
          href={downloadLink}
          download={`${sessionId}.mp4`}
          className='text-xs underline mt-2 mb-1 ml-2'
        >
          <FaDownload className='inline-flex' /> Previous
        </a>
      </SecondaryButton>
    );
  }

  let submitRenderDisplay = (
    <div>
      <CommonButton onClick={submitRenderVideo} isPending={isVideoGenerating}>
        Render
      </CommonButton>
    </div>
  );

  if (downloadVideoDisplay && renderedVideoPath && !isCanvasDirty) {
    submitRenderDisplay = (
      <div>
        <a href={renderedVideoPath} download={`${sessionId}.mp4`}>
          <CommonButton>Download</CommonButton>
        </a>
      </div>
    );
  }

  let submitRenderFullActionDisplay = submitRenderDisplay;

  const handleAudioOptionsSubmit = ({ isAudioDucking, syncAnimations, syncLayers }) => {
    setIsAudioDuckingEnabled(isAudioDucking);

    if (syncAnimations && syncLayers) {
      applySynchronizeLayersAndAnimationsToBeats();
    } else if (syncAnimations) {
      applySynchronizeAnimationsToBeats();
    } else if (syncLayers) {
      applySynchronizeLayersToBeats();
    }
    closeAlertDialog();
  };

  const showAdditionOptionsDialog = () => {
    openAlertDialog(
      <div>
        <div>
          <FaTimes
            className='absolute right-2 top-2 cursor-pointer'
            onClick={closeAlertDialog}
          />
        </div>
        <AudioOptionsDialog
          onSubmit={handleAudioOptionsSubmit}
          initialDucking={isAudioDuckingEnabled}
          closeDialog={closeAlertDialog}
        />
      </div>
    );
  };

  if (frameToolbarView === FRAME_TOOLBAR_VIEW.AUDIO) {
    submitRenderFullActionDisplay = (
      <div className='flex'>
        <div className='inline-flex'>{submitRenderDisplay}</div>
        <div className='inline-flex'>
          <div className='grid grid-cols-4'>
            <SecondaryButton onClick={submitRegenerateFrames}>
              <div>
                {' '}
                <FaRedo className='inline-flex' /> frames
              </div>
            </SecondaryButton>

            {prevDownloadLink}

            <SecondaryButton onClick={showAdditionOptionsDialog}>
              Additional Options
            </SecondaryButton>
          </div>
        </div>
      </div>
    );
  }

  const handleSeekBarChange = (value) => {
    setCurrentLayerSeek(value);
  
    // Compute cumulative start frames
    const cumulativeStartFrames = [];
    let totalFrames = 0;
    layers.forEach((layer) => {
      cumulativeStartFrames.push(totalFrames);
      totalFrames += layer.duration * 30; // Convert duration to frames
    });
  
    // Find the layer corresponding to the current seek position
    let layerIndex = layers.length - 1; // Default to last layer
    for (let i = 0; i < cumulativeStartFrames.length; i++) {
      const layerStart = cumulativeStartFrames[i];
      const layerEnd = layerStart + layers[i].duration * 30;
      if (value >= layerStart && value < layerEnd) {
        layerIndex = i;
        break;
      }
    }
  
    setSelectedLayerIndex(layerIndex);
    setSelectedLayer(layers[layerIndex]);
  };
  

  useEffect(() => {
    setEffectiveSliderRange(effectiveVisibleDisplaySliderRange);
  }, [effectiveVisibleDisplaySliderRange]);

  // Hide the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        openPopupLayerIndex !== null &&
        !durationChanged // Do not close if duration has changed
      ) {
        setOpenPopupLayerIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openPopupLayerIndex, durationChanged]);

  return (
    <div
      className={`border-r-2 ${bgColor} shadow-lg m-auto fixed top-0 ${containerWdidth} ${textColor} text-left left-0 toolbar-container`}
    >
      <div className='mt-[50px] relative'>
        <div className='w-full pb-1'>
          <div>
            <div className=' m-auto text-center'>
              <div onClick={showAudioTrackView} className='m-auto'>
                {expandButtonLabel}
              </div>
            </div>

            <div className='btn-container flex-w-full ml-2 mb-1'>
              <div className='basis-1/2 inline-flex mt-2'>{submitRenderFullActionDisplay}</div>
            </div>

            <div>
              <div className={`flex w-full ${bg2Color} p-1`}>
                <div className='inline-flex w-full'>{topSubToolbar}</div>
              </div>
            </div>
          </div>

          <div className='h-[74vh] w-full flex flex-row '>
            <div className='text-xs font-bold basis-1/4'>
              <div className='relative h-full'>
                {/* Previous and Next buttons */}
                <div className='relative h-full w-full overflow-y-clip' ref={parentRef}>
                  {layersList}
                  {layerSelectOverlay}
                </div>
              </div>
            </div>
            <div className='basis-3/4'>
              <div className='flex flex-row h-full'>
                <div className='inline-flex h-full ml-[10px]'>
                  <ReactSlider
                    key={`slider_layer_seek`}
                    orientation='vertical'
                    className='vertical-slider'
                    thumbClassName='thumb main-slider-thumb'
                    trackClassName='track'
                    min={effectiveVisibleDisplaySliderRange[0]}
                    max={effectiveVisibleDisplaySliderRange[1]}
                    value={currentLayerSeek}
                    onChange={(value) => {
                      handleSeekBarChange(value);
                    }}
                    onBeforeChange={() => setIsLayerSeeking(true)}
                    onAfterChange={() => setIsLayerSeeking(false)}
                  />
                </div>
                <div className='inline-flex dual-thumb h-auto w-[30px] ml-1'>
                  <DualThumbSlider
                    key={`dk_${totalDurationInFrames}`}
                    min={0}
                    max={totalDurationInFrames}
                    value={effectiveSliderRange}
                    onChange={handleViewRangeSliderChange} 
                  />
                </div>
                {audioTrackViewDisplay}
                <div className='inline-flex h-full'>
                  <TimeRuler
                    totalDuration={totalDuration}
                    visibleStartTime={visibleStartTime}
                    visibleEndTime={visibleEndTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openPopupLayerIndex !== null &&
        createPortal(
          <div
            className={`fixed z-50 p-2 rounded-lg ${bg2Color} shadow-lg`}
            style={{
              top: popupPosition.top, // Use the calculated top position
              left: '120px',
              transform: popupPosition.transform, // Remove the translateY(-50%)
              width: '200px',
              height: '150px',
            }}
            onClick={(e) => e.stopPropagation()}
            ref={popupRef}
          >
            <div className='relative text-center h-full'>
              <div className='absolute right-0 top-0'>
                <button onClick={onClosePopup}>
                  <FaEye />
                </button>
              </div>
              <div className='block w-full mt-2'>
                <input
                  type='number'
                  value={
                    pendingDuration != null
                      ? pendingDuration
                      : layers[openPopupLayerIndex].duration
                  }
                  onChange={(e) =>
                    layerDurationCellUpdated(e.target.value, openPopupLayerIndex)
                  }
                  className={`w-32 inline-block border border-neutral-100 p-1 rounded-lg ${textColor} ${bg2Color} pr-[30px] ${
                    durationChanged ? 'highlight' : ''
                  }`}
                />
                <label className='inline-block text-xs text-white ml-[-30px]'>Secs</label>
              </div>
              {durationChanged && (
                <div className='mt-2'>
                  <button
                    onClick={onUpdateDuration}
                    className={`px-3 py-1 text-xs text-white rounded bg-gray-900 m-auto ${
                      durationChanged ? 'highlight' : ''
                    }`}
                  >
                    Update
                  </button>
                </div>
              )}
              <div className='mt-auto absolute bottom-1 left-0 right-0'>
                <button
                  onClick={() => removeLayer(openPopupLayerIndex)}
                  className='px-3 py-1 text-xs rounded w-[80px] bg-red-900 text-neutral-100 hover:bg-red-800'
                >
                 <div className='flex m-auto'>
                 <div className='inline-flex'>
                 Remove 
                 </div>
                 
                 <FaTimes className='inline-flex mt-1' />
                  </div> 
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}
