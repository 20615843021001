import React, { useState, useEffect } from "react";
import CommonButton from "../../common/CommonButton.tsx";
import { VIDEO_GENERATION_MODEL_TYPES } from "../../../constants/Types.ts";
import { useColorMode } from "../../../contexts/ColorMode.js";
import TextareaAutosize from 'react-textarea-autosize';
import { VIDEO_MODEL_PRICES } from "../../../constants/ModelPrices.js";

export default function VideoPromptGenerator(props) {
  const {
    videoPromptText,
    setVideoPromptText,
    submitGenerateRequest,
    aiVideoGenerationPending,
    selectedVideoGenerationModel,
    setSelectedVideoGenerationModel,
    generationError,
    currentDefaultPrompt,
    submitGenerateNewVideoRequest,
    aspectRatio,
  } = props;

  const { colorMode } = useColorMode();

  const selectBG = colorMode === "dark" ? "bg-gray-800" : "bg-gray-200";
  const textBG = colorMode === "dark" ? "bg-gray-800" : "bg-gray-200 border-gray-600 border-2";

  const modelOptionMap = VIDEO_GENERATION_MODEL_TYPES.map((model) => (
    <option key={model.key} value={model.key}>
      {model.name}
    </option>
  ));

  // Load initial state from localStorage
  const [useStartFrame, setUseStartFrame] = useState(() => {
    const storedStartFrame = localStorage.getItem('defaultVideoStartFrame');
    return storedStartFrame === null ? true : storedStartFrame === 'true';
  });
  const [useEndFrame, setUseEndFrame] = useState(() => {
    const storedEndFrame = localStorage.getItem('defaultVideoEndFrame');
    return storedEndFrame === null ? true : storedEndFrame === 'true';
  });
  const [combineLayers, setCombineLayers] = useState(() => {
    const storedCombineLayers = localStorage.getItem('combineLayers');
    return storedCombineLayers === null ? false : storedCombineLayers === 'true';
  });
  const [clipLayerToAiVideo, setClipLayerToAiVideo] = useState(() => {
    const storedClipLayer = localStorage.getItem('clipLayerToAiVideo');
    return storedClipLayer === null ? false : storedClipLayer === 'true';
  });

  // Set defaults from localStorage or use initial values
  useEffect(() => {
    const defaultModel = localStorage.getItem('defaultVideoModel') || VIDEO_GENERATION_MODEL_TYPES[0].key;
    setSelectedVideoGenerationModel(defaultModel);
  }, [setSelectedVideoGenerationModel]);

  const setSelectedModelDisplay = (evt) => {
    const newModel = evt.target.value;
    setSelectedVideoGenerationModel(newModel);
    localStorage.setItem('defaultVideoModel', newModel);
  
    if (newModel === 'SDVIDEO') {
      setUseEndFrame(false); // Ensure end frame is false for SDVIDEO
    }
  };

  const handleSubmit = () => {
    const payload = {
      useStartFrame: selectedVideoGenerationModel === 'SDVIDEO' ? true : useStartFrame,
      useEndFrame: selectedVideoGenerationModel === 'SDVIDEO' ? false : useEndFrame,
      combineLayers,
      clipLayerToAiVideo,
    };

    submitGenerateNewVideoRequest(payload);
  };

  const handleStartFrameChange = (e) => {
    const checked = e.target.checked;
    setUseStartFrame(checked);
    localStorage.setItem('defaultVideoStartFrame', checked.toString());
  };

  const handleEndFrameChange = (e) => {
    const checked = e.target.checked;
    setUseEndFrame(checked);
    localStorage.setItem('defaultVideoEndFrame', checked.toString());
  };

  const handleClipLayerChange = (e) => {
    const checked = e.target.checked;
    setClipLayerToAiVideo(checked);
    localStorage.setItem('clipLayerToAiVideo', checked.toString());
  };

  // Calculate expected cost
  const modelPricing = VIDEO_MODEL_PRICES.find(model => model.key === selectedVideoGenerationModel);
  const priceObj = modelPricing ? modelPricing.prices.find(price => price.aspectRatio === aspectRatio) : null;
  const modelPrice = priceObj ? priceObj.price : 0;

  const errorDisplay = generationError ? (
    <div className="text-red-500 text-center text-sm">
      {generationError}
    </div>
  ) : null;

  return (
    <div>
      <div className="flex w-full mb-2 flex-col">
        <div className="w-full">
          <div className="text-xs font-semibold text-gray-300">
            This action will incur <span className="text-blue-300">{modelPrice} Credits</span>
          </div>
        </div>

        {/* Start Frame Checkbox */}
        <div className="flex w-full items-center mt-2">
          <label className="inline-flex items-center text-sm mr-4">
            <input
              type="checkbox"
              checked={useStartFrame}
              onChange={handleStartFrameChange}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2 text-xs">Start frame</span>
          </label>

          {/* End Frame Checkbox (Hidden for SDVIDEO) */}
          {selectedVideoGenerationModel === 'LUMA' && (
            <label className="inline-flex items-center text-sm mr-4">
              <input
                type="checkbox"
                checked={useEndFrame}
                onChange={handleEndFrameChange}
                className="form-checkbox h-4 w-4 text-blue-600"
              />
              <span className="ml-2 text-xs">End frame</span>
            </label>
          )}

          {/* Clip Layer to Video Duration Checkbox */}
          <label className="inline-flex items-center text-sm mr-4">
            <input
              type="checkbox"
              checked={clipLayerToAiVideo}
              onChange={handleClipLayerChange}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2 text-xs">Trim Scene</span>
          </label>
        </div>

        <div className="flex w-full items-center mt-2">
          <div className="w-[25%] text-sm font-bold">Model</div>
          <select
            onChange={setSelectedModelDisplay}
            className={`${selectBG} w-[75%] border border-gray-300 rounded-md p-1`}
            value={selectedVideoGenerationModel}
          >
            {modelOptionMap}
          </select>
        </div>
      </div>

      {/* Textarea (Hidden for SDVIDEO) */}
      {selectedVideoGenerationModel !== 'SDVIDEO' && (
        <TextareaAutosize
          onChange={(evt) => setVideoPromptText(evt.target.value)}
          placeholder="Add prompt text here"
          className={`${textBG} w-full m-auto p-4 rounded-lg`}
          minRows={3}
          value={videoPromptText}
        />
      )}

      <div className="text-center">
        <CommonButton onClick={handleSubmit} isPending={aiVideoGenerationPending}>
          Submit
        </CommonButton>
      </div>
      {errorDisplay}
    </div>
  );
}