import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonContainer from "../common/CommonContainer.tsx";
import { useColorMode } from "../../contexts/ColorMode.js";
import { useUser } from "../../contexts/UserContext.js";
import SecondaryButton from "../common/SecondaryButton.tsx";
import { useNavigate } from "react-router-dom";
import { FaChevronCircleLeft } from "react-icons/fa";
import { useAlertDialog } from "../../contexts/AlertDialogContext.js";
import AddCreditsDialog from "./AddCreditsDialog.js";
import axios from "axios";
import UpgradePlan from "../common/UpgradePlan.tsx";
import { getHeaders } from "../../utils/web.js";
import MusicPanelContent from "./MusicPanelContent.js";
import ImagePanelContent from "./ImagePanelContent.js";
import SettingsPanelContent from "./SettingsPanelContent.js";
import BillingPanelContent from "./BillingPanelContent.js";
import ToggleButton from "../common/ToggleButton.tsx";
import SceneLibraryHome from "../library/aivideo/SceneLibraryHome.js";

const PROCESSOR_SERVER = process.env.REACT_APP_PROCESSOR_API;

export default function UserAccount() {
  const { colorMode } = useColorMode();
  const textColor =
    colorMode === "dark" ? "text-neutral-100" : "text-neutral-800";
  const bgColor =
    colorMode === "dark" ? "bg-neutral-900" : "bg-neutral-100";
  const secondaryTextColor =
    colorMode === "dark" ? "text-neutral-400" : "text-neutral-500";
  const cardBgColor = colorMode === "dark" ? "bg-neutral-800" : "bg-white";
  const { openAlertDialog } = useAlertDialog();
  const { user, resetUser, getUserAPI } = useUser();
  const navigate = useNavigate();
  const [displayPanel, setDisplayPanel] = useState("account");

  if (!user) {
    return <span />;
  }

  let accountType = "Free";
  let accountActions = <span />;

  if (user.isPremiumUser) {
    accountType = "Premium";
    accountActions = (
      <SecondaryButton onClick={() => handleCancelMembership()}>
        Cancel Membership
      </SecondaryButton>
    );
  } else {
    accountActions = (
      <SecondaryButton onClick={() => handleUpgradeToPremium()}>
        Upgrade to Premium
      </SecondaryButton>
    );
  }

  const purchaseCreditsForUser = (amountToPurchase) => {
    const purchaseAmountRequest = parseInt(amountToPurchase);
    const headers = getHeaders();

    const payload = {
      amount: purchaseAmountRequest,
    };

    axios
      .post(`${PROCESSOR_SERVER}/users/purchase_credits`, payload, headers)
      .then(function (dataRes) {
        const data = dataRes.data;
        if (data.url) {
          window.open(data.url, "_blank");
          toast.success("Payment URL generated successfully!", {
            position: "bottom-center",
          });
        } else {
          console.error("Failed to get Stripe payment URL");
          toast.error("Failed to generate payment URL", {
            position: "bottom-center",
          });
        }
      })
      .catch(function (error) {
        console.error("Error during payment process", error);
        toast.error("Payment process failed", {
          position: "bottom-center",
        });
      });
  };

  const requestApplyCreditsCoupon = (couponCode) => {
    const headers = getHeaders();

    axios
      .post(
        `${PROCESSOR_SERVER}/users/apply_credits_coupon`,
        { couponCode: couponCode },
        headers
      )
      .then(function (dataRes) {
        const couponResponseData = dataRes.data;
        console.log(couponResponseData);
        toast.success("Coupon applied successfully!", {
          position: "bottom-center",
        });
      })
      .catch(function (error) {
        console.error("Error applying coupon", error);
        toast.error("Failed to apply coupon", {
          position: "bottom-center",
        });
      });
  };

  const showPurchaseCreditsAction = () => {
    openAlertDialog(
      <AddCreditsDialog
        purchaseCreditsForUser={purchaseCreditsForUser}
        requestApplyCreditsCoupon={requestApplyCreditsCoupon}
      />
    );
  };

  const handleUpgradeToPremium = () => {
    const alertDialogComponent = <UpgradePlan />;
    openAlertDialog(alertDialogComponent);
  };

  const handleCancelMembership = () => {
    const headers = getHeaders();
    axios
      .post(`${PROCESSOR_SERVER}/users/cancel_membership`, {}, headers)
      .then(function () {
        getUserAPI();
        toast.success("Membership canceled successfully!", {
          position: "bottom-center",
        });
      })
      .catch(function () {
        toast.error("Failed to cancel membership", {
          position: "bottom-center",
        });
      });
  };

  const logoutUser = () => {
    resetUser();
    navigate("/");
    toast.success("Logged out successfully!", {
      position: "bottom-center",
    });
  };

  const bottomToggleDisplay = (
    <div className="flex justify-end">
      <ToggleButton />
    </div>
  );


  const onSelectVideo = () => {

  }
  return (
    <CommonContainer>
      {/* Toast container */}
      <ToastContainer />

      {/* Header */}
      <div className="flex items-center mb-6">
        <div className="flex-1 text-left">
          <div
            onClick={() => navigate("/")}
            className="cursor-pointer flex items-center"
          >
            <FaChevronCircleLeft className="mr-2" />
            <span>Back</span>
          </div>
        </div>
        <div className="flex-1 flex justify-center items-center">
          <h2 className="text-2xl font-bold">Account Information</h2>
        </div>
        <div className="flex-1"></div>
      </div>

      {/* Main Content */}
      <div className="flex min-h-[100vh]">
        {/* Left Navigation */}
        <nav
          className={`w-1/4 p-4 ${bgColor} ${textColor} rounded-l-lg shadow-md border-r`}
        >
          <ul>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("account")}
            >
              Account
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("images")}
            >
              Images
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("sounds")}
            >
              Sounds
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("scenes")}
            >
              Scenes
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("videos")}
            >
              Videos
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("billing")}
            >
              Billing
            </li>
            <li
              className="mb-4 cursor-pointer"
              onClick={() => setDisplayPanel("settings")}
            >
              Settings
            </li>
          </ul>
        </nav>

        {/* Panel Content */}
        <div
          className={`${bgColor} ${textColor} p-6 rounded-r-lg shadow-md flex-grow flex flex-col`}
        >
          {/* Render panel content dynamically */}
          {displayPanel === "account" && (
            <div className="flex flex-col flex-grow">
              {/* User Info and Toggle Button Row */}
              <div className="flex items-center justify-between mb-8">
                {/* Profile Information */}
                <div className="flex items-center">
                  {/* Optional: Add Profile Picture */}
                  {user.profilePicture && (
                    <img
                      src={user.profilePicture}
                      alt="Profile"
                      className="w-16 h-16 rounded-full object-cover mr-4"
                    />
                  )}
                  <div>
                    <h2 className="text-2xl font-bold">{user.username}</h2>
                    <p className={`text-sm ${secondaryTextColor}`}>
                      {user.email}
                    </p>
                  </div>
                </div>

                {/* Toggle Button */}
                <div>
                  <ToggleButton />
                </div>
              </div>

              {/* Account Details */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 flex-grow">
                {/* Account Type */}
                <div
                  className={`p-6 rounded-lg shadow-md ${cardBgColor} ${textColor}`}
                >
                  <h3 className="text-lg font-semibold mb-2">
                    Account Type
                  </h3>
                  <p>{accountType}</p>
                  <div className="mt-4">{accountActions}</div>
                </div>

                {/* Credits Remaining */}
                <div
                  className={`p-6 rounded-lg shadow-md ${cardBgColor} ${textColor}`}
                >
                  <h3 className="text-lg font-semibold mb-2">
                    Credits Remaining
                  </h3>
                  <p>{user.generationCredits}</p>
                  <div className="mt-4">
                    <SecondaryButton onClick={showPurchaseCreditsAction}>
                      Purchase Credits
                    </SecondaryButton>
                  </div>
                </div>

                {/* Next Credit Refill */}
                <div
                  className={`p-6 rounded-lg shadow-md ${cardBgColor} ${textColor}`}
                >
                  <h3 className="text-lg font-semibold mb-2">
                    Next Credit Refill
                  </h3>
                  <p>{user.nextCreditRefill || "N/A"}</p>
                </div>
              </div>

              {/* Logout Button at Bottom */}
              <div className="mt-auto pt-6">
                <SecondaryButton onClick={logoutUser} className="w-full">
                  Logout
                </SecondaryButton>
              </div>
            </div>
          )}
          {displayPanel === "images" && <ImagePanelContent />}
          {displayPanel === "sounds" && <MusicPanelContent />}
          {displayPanel === "billing" && <BillingPanelContent />}
          {displayPanel === "settings" && (
            <SettingsPanelContent logoutUser={logoutUser} />
          )}
          {displayPanel === "scenes" && (
            <SceneLibraryHome hideSelectButton={true}/>
 )}

        </div>
      </div>
    </CommonContainer>
  );
}
