import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import './audioTrack/audioTrackSlider.css';

const AudioTrackSlider = ({ audioTrack, totalDuration, onUpdate }) => {
  const [audioDuration, setAudioDuration] = useState(0);
  const totalDurationInFrames = totalDuration * 30;

  useEffect(() => {
    const audio = new Audio(audioTrack.url);

    const handleLoadedMetadata = () => {
      setAudioDuration(audio.duration);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    // Cleanup the event listener on component unmount or when audioTrack.url changes
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.src = ''; // Stop loading the audio if needed
    };
  }, [audioTrack.url]);

  const startFrame = (audioTrack.startTime / totalDuration) * totalDurationInFrames;
  const defaultDuration = Math.min(totalDuration, 120);
  const durationFrames = (audioDuration > 0 ? audioDuration : defaultDuration) * 30;

  const handleChange = (value) => {
    const newStartTime = (value[0] / totalDurationInFrames) * totalDuration;
    const newDuration = ((value[1] - value[0]) / totalDurationInFrames) * totalDuration;
    // Use the correct identifier based on your data model
    const trackId = audioTrack._id || audioTrack.id;
    onUpdate(trackId, newStartTime, newDuration);
  };

  return (




    <ReactSlider
      className="vertical-slider audio-track-slider-component mr-1 ml-1 rail-track"
      thumbClassName="thumb train-thumb"
      trackClassName="track"
      orientation="vertical"
      min={0}
      max={totalDurationInFrames}
      defaultValue={[startFrame, startFrame + durationFrames]}
      onChange={handleChange}
      pearling
      renderThumb={(props, state) => (
        <div {...props}>
          <img
            src="/trainEngine.svg" // Ensure this path is correct
            alt="Train"
            className="train-image"
          />
        </div>
      )}
    />
    


  );
};

AudioTrackSlider.propTypes = {
  audioTrack: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
  }).isRequired,
  totalDuration: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AudioTrackSlider;
