import React, { useEffect, useState } from 'react';
import SecondaryButton from '../../common/SecondaryButton.tsx';
import TextareaAutosize from 'react-textarea-autosize';
import { IMAGE_MODEL_PRICES } from '../../../constants/ModelPrices.js';
import { IMAGE_GENERAITON_MODEL_TYPES } from "../../../constants/Types.ts";

export default function PromptViewer(props) {
  const {
    currentDefaultPrompt,
    submitGenerateRecreateRequest,
    showCreateNewPrompt,
    isGenerationPending,
    aspectRatio, // Make sure aspectRatio is being passed as a prop
  } = props;

  const [promptText, setPromptText] = useState(currentDefaultPrompt);
  const [selectedModel, setSelectedModel] = useState(IMAGE_GENERAITON_MODEL_TYPES[0].key);

  useEffect(() => {

    let storageModel = localStorage.getItem('defaultModel');
    if (storageModel && storageModel !== undefined) {
      setSelectedModel(storageModel);
    }
  }, []);
  const handleInputChange = (e) => {
    setPromptText(e.target.value);
  };

  const handleModelChange = (e) => {
    const newModel = e.target.value;
    setSelectedModel(newModel);
    localStorage.setItem('defaultModel', newModel);
  };

  const handleSubmit = () => {
    const payload = {
      prompt: promptText,
      model: selectedModel,
    };
    submitGenerateRecreateRequest(payload);
  };

  const modelOptionMap = IMAGE_GENERAITON_MODEL_TYPES.map((model) => (
    <option key={model.key} value={model.key}>
      {model.name}
    </option>
  ));

  // Updated price calculation
  const modelPricing = IMAGE_MODEL_PRICES.find(model => model.key === selectedModel);
  const priceObj = modelPricing
    ? modelPricing.prices.find(price => price.aspectRatio === aspectRatio)
    : null;
  const modelPrice = priceObj ? priceObj.price : 0;

  return (
    <div className="flex flex-col items-center space-y-4 bg-neutral-800 p-4 rounded-lg">
      {/* Expected Cost Display */}
      <div className="w-full">
        <div className="text-xs font-semibold text-gray-300">
          This action will incur <span className="text-blue-300">{modelPrice} Credits</span>
        </div>
      </div>

      {/* Model Selection Dropdown */}
      <div className="flex w-full mt-2 mb-2">
        <div className="inline-flex w-[25%]">
          <div className="text-xs font-bold">Model</div>
        </div>
        <select
          onChange={handleModelChange}
          value={selectedModel}
          className="w-[75%] p-2 border rounded bg-[#171717] text-[#fafafa]"
        >
          {modelOptionMap}
        </select>
      </div>

      {/* Prompt Textarea */}
      <TextareaAutosize
        className="text-left max-h-64 overflow-y-auto w-full px-2 py-2 border rounded bg-[#171717] text-[#fafafa]"
        value={promptText}
        onChange={handleInputChange}
        minRows={3}
        maxRows={10}
        style={{ resize: 'none' }}
      />

      {/* Action Buttons */}
      <div className="flex space-x-4">
        <SecondaryButton
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleSubmit}
          isPending={isGenerationPending}
        >
          Regenerate
        </SecondaryButton>
        <SecondaryButton
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={showCreateNewPrompt}
          isPending={isGenerationPending}
        >
          New
        </SecondaryButton>
      </div>
    </div>
  );
}