import React, { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';

const PROCESSOR_API_URL = process.env['REACT_APP_PROCESSOR_API'];

export default function VideoUnderlay(props) {
  const {
    aiVideoLayer,
    currentLayerSeek = 0,
    removeVideoLayer,
    canvasDimensions,
  } = props;

  const videoRef = useRef(null);


  const aiVideoLink = aiVideoLayer
    ? `${PROCESSOR_API_URL}${aiVideoLayer}`
    : null;

  useEffect(() => {
    if (videoRef.current && !isNaN(currentLayerSeek)) {
      const video = videoRef.current;

      const seekToTime = () => {
        video.currentTime = currentLayerSeek;
      };

      if (video.readyState >= 2) {
        seekToTime();
      } else {
        video.addEventListener('loadeddata', seekToTime, { once: true });
      }

      return () => {
        video.removeEventListener('loadeddata', seekToTime);
      };
    }
  }, [currentLayerSeek]);

  return aiVideoLink ? (
    <div
      style={{
        position: 'relative',
        width: `${canvasDimensions.width}px`,
        height: `${canvasDimensions.height}px`,
        overflow: 'hidden',
      }}
    >
      <div
        className="absolute top-0 left-0"
        style={{
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // Allow clicks to pass through if needed
          marginLeft: '1px',
          marginTop: '1px',
        }}
      >
        <video
          ref={videoRef}
          src={aiVideoLink}
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensures the video fills the container
          }}
        />
      </div>
      <div
        className="absolute top-0 left-0 z-10"
        style={{ pointerEvents: 'auto' }} // Ensure the overlay can be interacted with
      >
        <div className="bg-gray-900 border border-neutral-200 rounded-lg w-32 m-auto relative">
          Video Layer
          <FaTimes
            className="inline-flex ml-2 mt-[-2px] cursor-pointer hover:text-neutral-400"
            onClick={removeVideoLayer}
          />
        </div>
      </div>
    </div>
  ) : (
    <span />
  );
}
