import React, { useState } from "react";
import CommonButton from "../../../common/CommonButton.tsx";

const AudioOptionsDialog = ({
  onSubmit,
  initialDucking = false,
  closeDialog,
}) => {
  const [isAudioDucking, setIsAudioDucking] = useState(initialDucking);
  const [syncAnimations, setSyncAnimations] = useState(false);
  const [syncLayers, setSyncLayers] = useState(false);

  const handleAudioDuckingChange = (e) => {
    setIsAudioDucking(e.target.checked);
  };

  const handleSyncAnimationsChange = (e) => {
    setSyncAnimations(e.target.checked);
  };

  const handleSyncLayersChange = (e) => {
    setSyncLayers(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      isAudioDucking,
      syncAnimations,
      syncLayers,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isAudioDucking}
            onChange={handleAudioDuckingChange}
            className="mr-2"
          />
          Audio Ducking
        </label>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Beat Synchronization</label>
        <div className="flex items-center">
          <label className="flex items-center mr-4">
            <input
              type="checkbox"
              checked={syncAnimations}
              onChange={handleSyncAnimationsChange}
              className="mr-2"
            />
            Sync Animations to Beats
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={syncLayers}
              onChange={handleSyncLayersChange}
              className="mr-2"
            />
            Sync Layers to Beats
          </label>
        </div>
      </div>

      <div className="flex justify-end">
        <CommonButton type="submit">Submit</CommonButton>
      </div>
    </form>
  );
};

export default AudioOptionsDialog;
