// SettingsPanelContent.js

import React, { useState } from "react";
import SecondaryButton from "../common/SecondaryButton.tsx";
import { useColorMode } from "../../contexts/ColorMode.js";
import { useUser } from "../../contexts/UserContext.js";
import axios from "axios";
import { getHeaders } from "../../utils/web.js";
import { toast } from "react-toastify";

export default function SettingsPanelContent({ logoutUser }) {
  const { colorMode } = useColorMode();
  const textColor =
    colorMode === "dark" ? "text-neutral-100" : "text-neutral-800";
  const cardBgColor = colorMode === "dark" ? "bg-neutral-800" : "bg-white";
  const { user, getUserAPI } = useUser();

  const [username, setUsername] = useState(user.username || "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // Update Username Handler
  const handleUpdateUsername = () => {
    const headers = getHeaders();
    axios
      .post(
        "/api/users/update_username", // Adjust API endpoint as needed
        { username },
        headers
      )
      .then((response) => {
        toast.success("Username updated successfully!", {
          position: "bottom-center",
        });
        getUserAPI();
      })
      .catch((error) => {
        console.error("Error updating username", error);
        toast.error("Failed to update username", {
          position: "bottom-center",
        });
      });
  };

  // Update Password Handler
  const handleUpdatePassword = () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("New passwords do not match", {
        position: "bottom-center",
      });
      return;
    }
    const headers = getHeaders();
    axios
      .post(
        "/api/users/update_password", // Adjust API endpoint as needed
        { currentPassword, newPassword },
        headers
      )
      .then((response) => {
        toast.success("Password updated successfully!", {
          position: "bottom-center",
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      })
      .catch((error) => {
        console.error("Error updating password", error);
        toast.error("Failed to update password", {
          position: "bottom-center",
        });
      });
  };

  // Danger Zone Handlers
  const handleDeleteAllProjects = () => {
    // Add confirmation and API call
    if (window.confirm("Are you sure you want to delete all projects? This action is not reversible.")) {
      // Make API call to delete all projects
    }
  };

  const handleDeleteAllGenerations = () => {
    // Add confirmation and API call
    if (window.confirm("Are you sure you want to delete all generations? This action is not reversible.")) {
      // Make API call to delete all generations
    }
  };

  const handleDeleteAccount = () => {
    // Add confirmation and API call
    if (window.confirm("Are you sure you want to delete your account? This action is not reversible.")) {
      // Make API call to delete account
    }
  };


  const formBGColor = colorMode === "dark" ? "bg-gray-800" : "bg-gray-200";

  return (
    <div className={`p-6 rounded-lg shadow-md ${cardBgColor} ${textColor}`}>
      {/* Update Username */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Update Username</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={`border rounded-l px-4 py-2 w-full ${formBGColor}`}
          />
          <SecondaryButton
            onClick={handleUpdateUsername}
            className="rounded-l-none"
          >
            Update
          </SecondaryButton>
        </div>
      </div>

      {/* Update Password */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Update Password</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className={`border rounded px-4 py-2 w-full ${formBGColor}`}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={`border rounded px-4 py-2 w-full ${formBGColor}`}
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className={`border rounded px-4 py-2 w-full ${formBGColor}`}
          />
        </div>
        <div className="mt-4">

   
        <SecondaryButton onClick={handleUpdatePassword} >
          Update Password
        </SecondaryButton>
        </div>
      </div>

      {/* Logout Button */}
      <div className="mb-8">
        <SecondaryButton onClick={logoutUser}>Logout</SecondaryButton>
      </div>

      {/* Danger Zone */}
      <div className="mt-8">
        <h3 className="text-xl font-semibold text-red-600 mb-2">
          Danger Zone
        </h3>
        <p className="text-sm text-red-500 mb-4">
          This action is not reversible.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <SecondaryButton
            onClick={handleDeleteAllProjects}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            Delete All Projects
          </SecondaryButton>
          <SecondaryButton
            onClick={handleDeleteAllGenerations}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            Delete All Generations
          </SecondaryButton>
          <SecondaryButton
            onClick={handleDeleteAccount}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            Delete My Account
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}
