// ModelPrices.js

export const IMAGE_MODEL_PRICES = [
  {
    key: 'DALLE3',
    prices: [
      {
        aspectRatio: '1:1',
        price: 5
      },
      {
        aspectRatio: '16:9',
        price: 10
      },
      {
        aspectRatio: '9:16',
        price: 10
      },
    ]
  },
  {
    key: 'DALLE3HD',
    prices: [
      {
        aspectRatio: '1:1',
        price: 10
      },
      {
        aspectRatio: '16:9',
        price: 15
      },
      {
        aspectRatio: '9:16',
        price: 15
      },
    ]
  },
  {
    key: 'FLUX1PRO',
    prices: [
      {
        aspectRatio: '1:1',
        price: 10
      },
      {
        aspectRatio: '16:9',
        price: 15
      },
      {
        aspectRatio: '9:16',
        price: 15
      },
    ]
  },
  {
    key: 'FLUX1.1PRO',
    prices: [
      {
        aspectRatio: '1:1',
        price: 15
      },
      {
        aspectRatio: '16:9',
        price: 20
      },
      {
        aspectRatio: '9:16',
        price: 20
      },
    ]
  },
  {
    key: 'FLUX1DEV',
    prices: [
      {
        aspectRatio: '1:1',
        price: 5
      },
      {
        aspectRatio: '16:9',
        price: 10
      },
      {
        aspectRatio: '9:16',
        price: 10
      },
    ]
  }
]

export const VIDEO_MODEL_PRICES = [
  {
    key: 'LUMA',
    prices: [
      {
        aspectRatio: '1:1',
        price: 60
      },
      {
        aspectRatio: '16:9',
        price: 60
      },
      {
        aspectRatio: '9:16',
        price: 60
      },
    ]
  },
  {
    key: 'RUNWAYML',
    prices: [
      {
        aspectRatio: '1:1',
        price: 60
      },
      {
        aspectRatio: '16:9',
        price: 60
      },
      {
        aspectRatio: '9:16',
        price: 60
      },
    ]
  },
  {
    key: 'SDVIDEO',
    prices: [
      {
        aspectRatio: '1:1',
        price: 15
      },
      {
        aspectRatio: '16:9',
        price: 15
      },
      {
        aspectRatio: '9:16',
        price: 15
      },
    ]
  }
]

