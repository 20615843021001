import React, { useState } from 'react';
import ImageLibraryHome from './image/ImageLibraryHome';
import MusicLibraryHome from './audio/MusicLibraryHome'; // Adjust the import path as needed
import SceneLibraryHome from './aivideo/SceneLibraryHome';
import { FaChevronCircleLeft } from 'react-icons/fa';
import { useColorMode } from '../../contexts/ColorMode';

export default function LibraryHome(props) {
  const { resetImageLibrary } = props;
  const [selectedOption, setSelectedOption] = useState('Image');

  const { colorMode } = useColorMode();


  const renderContent = () => {
    switch (selectedOption) {
      case 'Image':
        return <ImageLibraryHome {...props} />;
      case 'Music':
        return <MusicLibraryHome {...props} />;
      case 'Scenes':
        return <SceneLibraryHome {...props} />;
      default:
        return null;
    }
  };

  const handleBack = () => {
    // Implement your back navigation logic here
    console.log('Back button clicked');
    resetImageLibrary();
  };

  const headings = {
    Image: 'Image Library',
    Music: 'Music Library',
    Scenes: 'Scene Library',
  };

  const textColor = colorMode === 'dark' ? 'text-white' : 'text-black';

  const bgColor = colorMode === 'dark' ? 'bg-gray-800' : 'bg-gray-200';

  return (
    <div className={`library-home mt-[60px] ${textColor} ${bgColor}`}>
      {/* Top Toolbar */}
      <div className="flex items-center justify-between p-4 border-b border-neutral-900 bg-gray-800">
        {/* Back Button */}
        <button onClick={handleBack} className="flex items-center text-lg">
          <FaChevronCircleLeft className="mr-2" />
          Back
        </button>
        
        

        {/* Heading */}
        <h2 className="text-lg font-bold">{headings[selectedOption]}</h2>

        {/* Options */}
        <div className="flex space-x-2">
          {['Image', 'Music', 'Scenes'].map((option) => (
            <button
              key={option}
              onClick={() => setSelectedOption(option)}
              className={`px-3 py-1 rounded ${
                selectedOption === option
                  ? 'bg-gray-200 text-neutral-800'
                  : 'bg-gray-900 text-white border border-neutral-100'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
}