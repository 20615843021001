import React from "react";
import SecondaryButton from "../../common/SecondaryButton.tsx";
import { useNavigate } from "react-router-dom";
import { FaExpandArrowsAlt , FaDownload} from "react-icons/fa";
import { useAlertDialog } from "../../../contexts/AlertDialogContext.js";
import SingleSelect from "../../common/SingleSelect.js";
import { TbArrowBackUp } from "react-icons/tb";



export default function CanvasControlBar(props) {
  const {
    downloadCurrentFrame,
    isExpressGeneration,
    sessionId,
    toggleStageZoom,
    requestRegenerateSubtitles,
    displayZoomType,
    stageZoomScale,
    requestRegenerateAnimations,
  } = props;

  const { openAlertDialog, closeAlertDialog } = useAlertDialog();
  const navigate = useNavigate();
  let expressGenerationLink = null;

  const routeToExpress = () => {
    navigate(`/quick_video/${sessionId}`);
  };

  const showAddSubtitlesDialog = () => {
    openAlertDialog(
      <AddSubtitlesDialog
        requestRegenerateSubtitles={requestRegenerateSubtitles}
        closeAlertDialog={closeAlertDialog}
        requestRegenerateAnimations={requestRegenerateAnimations}
      />
    );
  };

  if (isExpressGeneration && sessionId) {
    expressGenerationLink = (
      <div>
        <SecondaryButton onClick={routeToExpress}>
          <TbArrowBackUp className="inline-flex text-xs" /> Express Mode
        </SecondaryButton>
      </div>
    );
  }

  let subtitlesTextDisplay = null;
  if (isExpressGeneration) {
    subtitlesTextDisplay = (
      <div>
        <SecondaryButton onClick={showAddSubtitlesDialog}>
          Express Ops
        </SecondaryButton>
      </div>
    );
  }

  let expandButtonText = (
    <div className="flex">
      <FaExpandArrowsAlt className="inline-flex mt-[2px] mr-2" /> Expand
    </div>
  );
  if (displayZoomType === "fill") {
    expandButtonText = (
      <div className="flex">
        <FaExpandArrowsAlt className="inline-flex mt-[2px] mr-2" /> Collapse
      </div>
    );
  }

  return (
    <div className="h-[25px] md:mt-[-10px] md:mb-[10px] relative flex justify-center" style={{ zIndex: 5 }}>
      <div className="flex flex-row gap-4">
        {expressGenerationLink}
        <div>
          <SecondaryButton onClick={toggleStageZoom}>
            {expandButtonText}
          </SecondaryButton>
        </div>
        <div>
          <SecondaryButton onClick={downloadCurrentFrame}>
            <FaDownload className="text-xs inline-flex mr-1"/> Frame
          </SecondaryButton>
        </div>
        {subtitlesTextDisplay}
      </div>
    </div>
  );
}

const AddSubtitlesDialog = (props) => {
  const { requestRegenerateSubtitles, requestRegenerateAnimations, closeAlertDialog } = props;
  const requestRegenerateAndClose = () => {
    requestRegenerateSubtitles();
    closeAlertDialog();
  };

  const requestRegenerateAnimationsAndClose = () => {
    requestRegenerateAnimations();
    closeAlertDialog();

  }
  return (
    <div>
      <div>
        <h1>Regenerate Subtitles</h1>
      </div>
      <div className="mt-4 mb-2">
        <SecondaryButton onClick={requestRegenerateAndClose} >
          Regenerate Subtitle
        </SecondaryButton>
      </div>
      <div className="mt-4 mb-2">
        <SecondaryButton onClick={requestRegenerateAnimationsAndClose} >
          Regenerate Animations
        </SecondaryButton>
      </div>    
    </div>
  );
};
